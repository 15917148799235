import Cookies from "js-cookie"

import { COOKIE_KEYS } from "~/lib/constants/cookies"

/**
 * The function sets a cookie to indicate that the USP banner is closed for a specific locale.
 * @param {string} locale - A string representing the locale or language for which the USP
 * (User-Specific Privacy) banner is being closed.
 */
export function setUSPClosedCookie(locale: string) {
  Cookies.set(COOKIE_KEYS.uspBanner(locale), `1`, { expires: 30 })
}

// Checking if the USP banner is allowed to be shown
export function isAllowedToShowUSP(locale: string) {
  return Cookies.get(COOKIE_KEYS.uspBanner(locale)) !== "1"
}
