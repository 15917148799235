import { useRef, useState } from "react"

import { useScrollY } from "@unlikelystudio/react-hooks"

export type ScrollDirection = "up" | "down"

type useScrollDirectionReturn = {
  scrollDirection: ScrollDirection
  isScrollUp: boolean
  isScrollDown: boolean
  isTop: boolean
}

export function useScrollDirection({ active } = { active: true }): useScrollDirectionReturn {
  const previousYPosition = useRef(0)
  const [isTop, setIsTop] = useState(true)

  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>("down")
  const onScroll = (yValue: number) => {
    setIsTop(yValue === 0)
    yValue > previousYPosition?.current ? setScrollDirection("down") : setScrollDirection("up")
    previousYPosition.current = yValue
  }
  useScrollY({ onChange: onScroll, active: active ?? true })

  const isScrollUp = scrollDirection === "up"
  const isScrollDown = scrollDirection === "down"

  return {
    scrollDirection,
    isScrollUp,
    isScrollDown,
    isTop,
  }
}
