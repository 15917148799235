"use client"

import clsx from "clsx"

import InlineCta from "~/components/ui/InlineCta"
import { LangSwitcher } from "~/components/ui/LangSwitcher"
import Icon from "~/components/abstracts/Icon"
import type { TNavigation } from "~/components/globals/Navigation/_data/serializer"

import { sprinkles } from "~/styles/sprinkles.css"
import { grid } from "~/styles/variants"

import * as css from "./styles.css"

type MobilePanelFooterProps = {
  navigation: NonNullable<TNavigation>
}

export function MobilePanelFooter({ navigation }: MobilePanelFooterProps) {
  return (
    <div className={grid({ type: "base" })}>
      <div className={clsx(css.MobilePanelFooter)}>
        <div className={sprinkles({ flex: "1" })}>
          <InlineCta
            title={navigation.accountLink.children}
            transform="uppercase"
            className={sprinkles({
              alignItems: "center",
              gap: 8,
              p: 10,
            })}
            {...navigation.accountLink}
          >
            <Icon width={18} name="Account" theme="blue-light" />
            <span>{navigation.accountLink.children}</span>
          </InlineCta>
        </div>
        <InlineCta
          title={navigation.faqLink.children}
          transform="uppercase"
          className={sprinkles({
            alignItems: "center",
            gap: 8,
            p: 10,
          })}
          {...navigation.faqLink}
        >
          <Icon width={18} name="HelpCircle" theme="blue-light" />
          <span>{navigation.faqLink.children}</span>
        </InlineCta>
        <div className={sprinkles({ flex: "1", justifyContent: "flex-end", display: "flex" })}>
          <LangSwitcher />
        </div>
      </div>
    </div>
  )
}
