import { useAtom } from "jotai"

import { NavigationMobileVisibility, panelIndexAtom } from "~/components/abstracts/Navigation/store"

export function usePanelIndex() {
  return useAtom(panelIndexAtom)
}

export function useNavigationMobileVisibility() {
  return useAtom(NavigationMobileVisibility)
}
