"use client"

import type { PropsWithClassName } from "~/@types/generics"
import InlineCta from "~/components/ui/InlineCta"
import Icon from "~/components/abstracts/Icon"
import type { TNavigation } from "~/components/globals/Navigation/_data/serializer"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import type { Sprinkles } from "~/styles/sprinkles.css"

type ClientAccountCtaProps = PropsWithClassName<{ sprinklesCss?: Sprinkles; navigation: NonNullable<TNavigation> }>

export function ClientAccountCta({ className, sprinklesCss, navigation }: ClientAccountCtaProps) {
  const t = useTranslate()

  return (
    <InlineCta
      aria-label={t("aria_navigation_account")}
      className={className}
      sprinklesCss={sprinklesCss}
      {...navigation.accountLink}
    >
      <Icon height={18} name="Account" />
    </InlineCta>
  )
}
