import type { ZodSchema } from "zod"

import type { InferReturn, Nullish } from "~/@types/generics"
import type { getSfMetaobject } from "~/lib/shopify/queries/get-sf-metaobject"
import { handleZodSafeParse } from "~/lib/zod/utils/handle-zod-safe-parse"

export type UnlikelyMetaobject = NonNullable<InferReturn<typeof getSfMetaobject>["metaobject"]>

export type TMetaObject = Record<string, string>

export function serializeMetaobject<Schema extends ZodSchema>(schema: Schema, metaobject: Nullish<UnlikelyMetaobject>) {
  if (!metaobject) {
    return null
  }

  const processedMetaobject = metaobject.fields.reduce((acc, field) => {
    if (!field.key || !field.value) {
      return acc
    }
    acc[field.key] = field.value
    return acc
  }, {} as TMetaObject)

  return handleZodSafeParse(schema, processedMetaobject, { errorContext: `Serialize metaobject: ${metaobject.type}` })
}
