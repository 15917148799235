import { useAtomValue, useSetAtom } from "jotai"

import { cookiesConsentDoneAtom } from "~/managers/CookiesConsentManager/store"

export function useCookiesConsentDone() {
  return useAtomValue(cookiesConsentDoneAtom)
}

export function useSetCookiesConsentDone() {
  return useSetAtom(cookiesConsentDoneAtom)
}
