import clsx from "clsx"

import type { InferReturn } from "~/@types/generics"
import InlineCta, { type InlineCtaProps } from "~/components/ui/InlineCta"
import type { serializeSubNavLink } from "~/components/globals/Navigation/components/SubNavLink/_data/serializer"

import { sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

export type SubNavLinkProps = { link: InferReturn<typeof serializeSubNavLink> } & InlineCtaProps

export function SubNavLink({ link, className, style, ...props }: SubNavLinkProps) {
  return (
    <InlineCta
      className={clsx(className, sprinkles({ gap: 4, textAlign: "left" }))}
      style={{ ...style, ...(link.color ? { color: link.color } : {}) }}
      {...link.link}
      {...props}
    >
      {link.link.children}
      {link.tag && (
        <span
          className={clsx(
            text({ design: "riposte-10", transform: "uppercase" }),
            sprinkles({ verticalAlign: "top", mL: 5 })
          )}
          style={link.tagColor ? { color: link.tagColor } : {}}
        >
          {link.tag}
        </span>
      )}
    </InlineCta>
  )
}
