import { useEffect, useRef, useState } from "react"
import { useInterval } from "usehooks-ts"

import { useOnRouteChange } from "~/hooks/useOnRouteChange"

export type GorgiasChat = {
  open: () => void
  close: () => void
  on: (event: string, func: any) => void
} | null

declare global {
  interface Window {
    GorgiasChat: GorgiasChat
  }
}
type UseChatBotProps = {
  disable?: boolean
}

const TRIGGER_DELAY = 100

const useChatBot = ({ disable }: UseChatBotProps) => {
  const chatBot = useRef<GorgiasChat>(null)

  const [isRunning, toggleIsRunning] = useState(false)
  const [loadScript, setLoadScript] = useState(false)
  const [loading, setLoading] = useState(false)
  const [ready, setReady] = useState(false)
  const [open, setOpen] = useState(false)

  useInterval(
    () => {
      if (window.GorgiasChat && window.GorgiasChat.hasOwnProperty("on")) {
        window.GorgiasChat.on("widget:closed", function () {
          setOpen(false)
        })
        setReady(true)
      }
    },
    disable ? null : isRunning ? TRIGGER_DELAY : null
  )

  const init = () => {
    chatBot.current = window.GorgiasChat
    setOpen(true)
  }

  useEffect(() => {
    if (ready) {
      init()
      setLoading(false)
    }
  }, [ready, setLoading])

  const openChatBot = (state: boolean) => {
    if (!chatBot?.current) {
      setLoadScript(true)
      setLoading(true)
      toggleIsRunning(true)
    } else {
      setOpen(state)
    }
  }

  useEffect(() => {
    if (open) {
      chatBot?.current?.open()
    } else {
      chatBot?.current?.close()
    }
  }, [open])

  useOnRouteChange(() => {
    if (!open) {
      chatBot?.current?.close()
      setOpen(false)
    }
  })

  return {
    open,
    loading,
    loadScript,
    openChatBot,
  }
}

export default useChatBot
