import type { UnlikelyProduct, UnlikelyVariant } from "@unlikelystudio/commerce-connector"

import { getProductMetafieldValue, getVariantMetafieldValue } from "~/lib/shopify/constants"

export function getIsBundleProduct(product: Pick<UnlikelyProduct, "metafields">) {
  return getProductMetafieldValue(product.metafields, "IS_BUNDLE") === "true" ? true : false
}

export function getIsBundleProductFromVariant(variant: UnlikelyVariant | null) {
  return getVariantMetafieldValue(variant?.metafields, "BUNDLE_APP")
}
