import { useMutation, type UseMutationOptions } from "@tanstack/react-query"

import type { UnlikelyCustomer, UnlikelyError } from "@unlikelystudio/commerce-connector"

import { SHOPIFY_NEWSLETTER_PARAMS } from "~/lib/api/constants"
import { requestNextApiEndpoint } from "~/lib/api/utils/request-endpoint"
import { useCustomerTags } from "~/lib/shopify/hooks/useCustomerTags"
import { uniq } from "~/utils/uniq"

export type NewsletterMutationParams = {
  email: string
  acceptsMarketing: boolean
}

type NewsletterMutationResponse = {
  success: boolean
  data?: {
    errors?: UnlikelyError<string>[]
    customer?: UnlikelyCustomer
  }
}

export const useShopifyNewsletterMutation = (
  tags: string[] = [],
  mutationOptions?: UseMutationOptions<NewsletterMutationResponse, unknown, NewsletterMutationParams>
) => {
  const { lang, customerTags } = useCustomerTags()

  return useMutation<NewsletterMutationResponse, unknown, NewsletterMutationParams>(
    async ({ email, acceptsMarketing }) => {
      return await requestNextApiEndpoint(SHOPIFY_NEWSLETTER_PARAMS, {
        payload: {
          email,
          lang,
          tags: uniq([...customerTags, ...tags]),
          acceptsMarketing,
        },
      })
    },
    mutationOptions
  )
}
