import marketsConfig from "~/config/markets.json"
import { getI18nMarkets } from "~/lib/i18n/utils/get-i18n"

export function getAllCountries() {
  const i18nMarket = getI18nMarkets()

  return Object.entries(marketsConfig)
    .filter(([market]) => i18nMarket.includes(market))
    .flatMap(([_, countries]) => countries)
}
