import { usePathname } from "next/navigation"

import { useScrollDirection } from "~/hooks/useScrollDirection"
import { isProductPage } from "~/components/ui/Link/utils/href-resolver"
import { useNavigationMobileVisibility } from "~/components/abstracts/Navigation/hooks"

export function useMobileTransparentNavigation() {
  const { isTop } = useScrollDirection()
  const [isMobileNavigation] = useNavigationMobileVisibility()
  const isPDP = usePathname()?.includes(`/${isProductPage}`)

  const isTransparentBackground = isPDP && isTop
  const isWhiteIcon = isPDP && isTop && !isMobileNavigation

  return {
    isTransparentBackground,
    isWhiteIcon,
  }
}
