"use client"

import type { ComponentProps } from "react"
import clsx from "clsx"

import { Accordion } from "@unlikelystudio/react-abstract-components"

import InlineCta from "~/components/ui/InlineCta"
import type { TInlineCta } from "~/components/ui/InlineCta/_data/serializer"
import NewsletterForm from "~/components/ui/NewsletterForm"
import type { TNewsletterForm } from "~/components/ui/NewsletterForm/_data/serializer"
import { NewsletterFormPX } from "~/components/globals/Footer/MiddleFooter/constants"
import { PanelLinksWithTitle } from "~/components/globals/Navigation/components/PanelLinksWithTitle"

import { sprinkles } from "~/styles/sprinkles.css"
import { grid, text } from "~/styles/variants"

import * as css from "./styles.css"

type TPanelLinksWithTitle = {
  title: string
  links: TInlineCta[]
}

export type MiddleFooterProps = {
  newsletterForm: TNewsletterForm
  panelLinksWithTitle: TPanelLinksWithTitle[]
  isMobile?: boolean
} & ComponentProps<"div">

function MiddleFooter({ newsletterForm, panelLinksWithTitle, className, isMobile }: MiddleFooterProps) {
  return (
    <div className={clsx(className, css.Newsletter, grid({ type: "base" }))}>
      {newsletterForm && (
        <NewsletterForm
          tags={["newsletter_footer"]}
          formData={newsletterForm}
          sprinklesCss={{
            textAlign: "center",
            gridColumn: { mobile: "span 12", tablet: "span 6" },
            pX: { mobile: 15, tablet: NewsletterFormPX },
            pY: 40,
            mX: { tablet: "auto" },
            justifyContent: "center",
          }}
          className={css.newsletterWrapper}
          gap={20}
          responsiveGap={{ tablet: 10 }}
          titleClassName={text({
            design: "spezia-24-14",
            color: "blue-med",
            transform: "uppercase",
            weight: "semiBold",
            letterSpacing: 0.6,
          })}
        />
      )}
      {isMobile ? (
        <Accordion className={sprinkles({ gridColumn: "span 12" })}>
          {panelLinksWithTitle.map((item, index) => {
            return (
              <PanelLinksWithTitle
                isMobile
                index={index}
                className={clsx(css.PanelLinksWithTitle, css.Accordion, index === 0 && css.FirstAccordion)}
                key={`panel-links-with-title-${index}`}
                title={item.title}
              >
                {item.links.map((link, index) => {
                  return (
                    <InlineCta
                      key={"LinksWithTitle" + index}
                      theme="blue-med"
                      className={css.linkItem}
                      type={"smallMd"}
                      {...link}
                    />
                  )
                })}
              </PanelLinksWithTitle>
            )
          })}
        </Accordion>
      ) : (
        <>
          {panelLinksWithTitle.map((panelLinksWithTitle, index) => {
            return (
              <PanelLinksWithTitle
                className={css.PanelLinksWithTitle}
                key={`panel-links-with-title-${index}`}
                title={panelLinksWithTitle.title}
              >
                {panelLinksWithTitle.links.map((link, index) => {
                  return (
                    <InlineCta key={"LinksWithTitle" + index} theme="blue-med" className={css.linkItem} {...link} />
                  )
                })}
              </PanelLinksWithTitle>
            )
          })}
        </>
      )}
    </div>
  )
}

export default MiddleFooter
