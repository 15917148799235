import { useEffect, type ComponentProps } from "react"
import clsx from "clsx"
import { useInView } from "react-intersection-observer"

import { useGoogleReCaptcha } from "~/providers/RecaptchaProvider"

import * as css from "./styles.css"

function RecaptchaHandler({ className, children }: ComponentProps<"div">) {
  const [inViewRef, inView] = useInView({ initialInView: false })
  const { loadRecaptcha } = useGoogleReCaptcha()

  useEffect(() => {
    if (inView) {
      loadRecaptcha?.()
    }
  }, [inView])

  return (
    <div ref={inViewRef} className={clsx(css.RecaptchaHandler, className)}>
      <div className={css.marker} />
      {children}
    </div>
  )
}

export default RecaptchaHandler
