import type { ComponentProps } from "react"
import clsx from "clsx"

import { Asset } from "~/components/ui/Asset"
import { type Sizes } from "~/components/ui/Image"
import { Link } from "~/components/ui/Link"
import RichText from "~/components/ui/RichText"
import type { TPushImage } from "~/components/globals/Navigation/components/PanelPushImage/_data/serializer"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type PanelPushImageProps = TPushImage &
  ComponentProps<typeof Link> & {
    ratio: Sprinkles["ratio"]
    sizesFromBreakpoints: Sizes
    isMobile?: boolean
  }

export function PanelPushImage({
  asset,
  link,
  title,
  titleMobile,
  ratio,
  sizesFromBreakpoints,
  className,
  isMobile = false,
  ...props
}: PanelPushImageProps) {
  return (
    <Link
      className={clsx(
        className,
        sprinkles({
          display: "flex",
          flexDirection: "column",
          gap: 8,
          position: "relative",
        })
      )}
      {...link}
      {...props}
    >
      <Asset
        image={asset.image}
        imageMobile={isMobile ? asset.imageMobile : null}
        asPlaceholder
        ratio={ratio}
        sizesFromBreakpoints={sizesFromBreakpoints}
      />
      {isMobile ? (
        titleMobile ? (
          <RichText className={css.MobileTitle} render={titleMobile} />
        ) : (
          <p className={css.MobileTitle}>{title}</p>
        )
      ) : (
        <p className={clsx(text({ letterSpacing: 1, transform: "uppercase", weight: "medium" }))}>{title}</p>
      )}
    </Link>
  )
}
