import { type ComponentProps, type ElementType, type ReactNode } from "react"

import { useNavigationMobileVisibility } from "~/components/abstracts/Navigation/hooks"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

type AbstractMobileToggleButtonProps = {
  children: {
    open: ReactNode
    close: ReactNode
  }
  onOpen: () => void
  onClose: () => void
  as?: ElementType
} & Omit<ComponentProps<"button">, "children">

export function AbstractMobileToggleButton({
  onOpen,
  onClose,
  children,
  className,
  as = "button",
  ...props
}: AbstractMobileToggleButtonProps) {
  const Tag = as

  const [visible, setVisible] = useNavigationMobileVisibility()

  const t = useTranslate()

  function handleClick() {
    setVisible((prev) => !prev)

    if (visible) {
      // INP TRICKS DELAY 1 TICK
      setTimeout(() => onClose(), 1)
    } else {
      // INP TRICKS DELAY 1 TICK
      setTimeout(() => onOpen(), 1)
    }
  }

  return (
    <Tag
      onClick={handleClick}
      className={className}
      aria-label={t(visible ? "aria_mobile_nav_close" : "aria_mobile_nav_open")}
      {...props}
    >
      {visible ? children.open : children.close}
    </Tag>
  )
}
