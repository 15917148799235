import type { UnlikelyVariant } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { COLORS_SELECTOR_TYPES, type ColorsSelectorTypeKeys } from "~/lib/shopify/constants/types"
import type { TVariant } from "~/lib/shopify/serializers/serialize-variants"
import type { TImage } from "~/components/ui/Image/_data/serializer"
import { OPTION_COLOR } from "~/components/ui/Product/ProductHeader/_data/constants"
import { uniq } from "~/utils/uniq"

export type TColorWithDotImage = {
  value: string
  image: Nullish<TImage>
  availableForSale?: boolean
}

export function getUniqColorsWithDotImage(variants: TVariant[], type: ColorsSelectorTypeKeys): TColorWithDotImage[] {
  const uniqColors = uniq(variants.map((variant) => getVariantColor(variant)).filter(Boolean))
  return uniqColors.map((color) => {
    const variantWithDotColor = variants.find((variant) => getVariantColor(variant) === color && variant.colorDotImage)
    const variantsWithDotColor = variants.filter((variant) => getVariantColor(variant) === color)
    const uniqColorIsOneVariantAvailableForSale = variantsWithDotColor.some((variant) => variant.availableForSale)

    if (type === COLORS_SELECTOR_TYPES.PRODUCT) {
      return {
        value: color,
        image: variantWithDotColor?.colorDotImage ?? null,
        availableForSale: uniqColorIsOneVariantAvailableForSale ?? true,
      }
    } else {
      return {
        value: color,
        image: variantWithDotColor?.colorDotImage ?? null,
      }
    }
  })
}

export function getVariantColor(variant: Nullish<Pick<UnlikelyVariant, "selectedOptions">>) {
  return variant?.selectedOptions?.find((option) => option.name === OPTION_COLOR)?.value ?? null
}
