import type { Nullish } from "~/@types/generics"

export function parseTextsFromMetatield(stringifiedTexts: Nullish<string>) {
  if (!stringifiedTexts) {
    return null
  }

  const parsedReferences = JSON.parse(stringifiedTexts)

  if (!Array.isArray(parsedReferences)) {
    return null
  }

  return parsedReferences as string[]
}
