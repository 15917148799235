import React, { type ComponentProps } from "react"
import clsx from "clsx"

import Icon from "~/components/abstracts/Icon"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

export type SelectProps = {
  errorBackgroundColor?: Extract<Sprinkles["bgColor"], "white" | "grey-light-bg">
  placeholder?: string
  children: {
    options: React.ReactNode
    label?: React.ReactNode
    error?: React.ReactNode
  }
} & Omit<ComponentProps<"select">, "children"> &
  css.SelectVariants

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ className, children, errorBackgroundColor = "grey-light-bg", placeholder, theme, ...props }, ref) => {
    return (
      <div className={clsx(css.SelectContainer, className)}>
        <select ref={ref} className={css.Select({ theme: !props.value ? "light" : "default" ?? theme })} {...props}>
          {placeholder && (
            <option value="" disabled selected hidden>
              {placeholder}
            </option>
          )}
          {children.options}
        </select>
        <Icon name="ChevronDown" theme="blue-deep" width={20} className={css.ChevronDownIcon} />

        <div
          className={clsx(
            css.LabelTop,
            sprinkles({
              position: "absolute",
              bgColor: errorBackgroundColor,
              marginLeft: 12,
              pX: 8,
            })
          )}
        >
          {children.label}
        </div>

        <div
          className={clsx(
            css.ErrorMessage,
            sprinkles({
              position: "absolute",
              bgColor: errorBackgroundColor,
              marginLeft: 12,
              pX: 8,
            })
          )}
        >
          {children.error}
        </div>
      </div>
    )
  }
)
