"use client"

import { Suspense, useEffect } from "react"
import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import { useLocale } from "~/lib/i18n/hooks/useLocale"
import { getIntl, getIsMultiLang, getLang, getLocaleByCountry, getMarketCode } from "~/lib/i18n/utils/get-i18n"
import useMarketCookie from "~/lib/shopify/hooks/useMarketCookie"
import Icon from "~/components/abstracts/Icon"
import { Stack } from "~/components/abstracts/Stack"
import { PopinMarket } from "~/components/globals/PopinMarket"
import { usePopin } from "~/managers/PopinManager"

import { hideFrom } from "~/styles/utils/show-hide"

import * as css from "./style.css"

export type LangSwitcherProps = PropsWithClassName<{}>

function LangSwitcher({ className }: LangSwitcherProps) {
  const { add } = usePopin()
  const locale = useLocale()
  const lang = getLang(locale)
  const intl = getIntl(locale)

  const languageNames = new Intl.DisplayNames(intl, { type: "language" })

  const { getPopinHasInteracted, getVercelCountryCode } = useMarketCookie()

  useEffect(() => {
    const vercelCountryCode = getVercelCountryCode()
    const marketCode = getMarketCode(locale)
    const isDifferentCountryCode = vercelCountryCode ? vercelCountryCode !== marketCode : false
    const isMultiLang = getIsMultiLang(locale)

    if (!getPopinHasInteracted() && (isDifferentCountryCode || isMultiLang)) {
      const vercelLocale = vercelCountryCode && getLocaleByCountry(vercelCountryCode)
      const country = vercelLocale ? getMarketCode(vercelLocale) : marketCode
      const lang = vercelLocale ? getLang(vercelLocale) : undefined

      add(
        <Suspense>
          <PopinMarket country={country} lang={lang} />
        </Suspense>
      )
    }
  }, [])

  return (
    <Stack
      asChild
      gap={10}
      sprinklesCss={{
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        pL: 5,
        pY: 5,
      }}
      className={clsx(className, css.LangSwitcher)}
    >
      <button
        onClick={() => {
          add(
            <Suspense>
              <PopinMarket />
            </Suspense>
          )
        }}
        data-comp="UI/LangSwitcher"
      >
        <Icon className={hideFrom("desktop", "block")} width={16} name="Earth" theme="blue-light" />

        <span>{languageNames.of(lang)}</span>
        <Icon name="ChevronDown" theme="blue-deep" width={16} />
      </button>
    </Stack>
  )
}

export { LangSwitcher }
