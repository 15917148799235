"use client"

import clsx from "clsx"

import { useMobileTransparentNavigation } from "~/hooks/useMobileTransparentNavigation"
import InlineCta from "~/components/ui/InlineCta"
import Icon from "~/components/abstracts/Icon"
import type { TNavigation } from "~/components/globals/Navigation/_data/serializer"
import { ClientAccountCta } from "~/components/globals/Navigation/components/ClientAccountCta"
import { BurgerCta } from "~/components/globals/Navigation/components/ClientBurgerCta"
import { CartCta } from "~/components/globals/Navigation/components/ClientCartCta"
import { NavigationLinks } from "~/components/globals/Navigation/components/ClientNavigationLinks"
import { NavigationLogo } from "~/components/globals/Navigation/components/ClientNavigationLogo"
import { DesktopPanels } from "~/components/globals/Navigation/components/Desktop/ClientDesktopPanel"

import { sprinkles } from "~/styles/sprinkles.css"
import { grid } from "~/styles/variants"

import * as css from "./styles.css"

type NavigationComponentProps = {
  navigation: NonNullable<TNavigation>
}

export function NavigationComponent({ navigation }: NavigationComponentProps) {
  const { isTransparentBackground, isWhiteIcon } = useMobileTransparentNavigation()

  return (
    <nav className={css.Navigation}>
      <div className={grid({ type: "base" })}>
        <header
          className={clsx(
            css.NavigationHeader,
            sprinkles({
              bgColor: { mobile: isTransparentBackground ? "transparent" : "white", tablet: "white" },
            })
          )}
        >
          <div className={sprinkles({ flex: "1" })}>
            <NavigationLogo logoAlt={navigation.logoAlt} />
          </div>
          <NavigationLinks
            links={navigation.links}
            className={sprinkles({ display: { desktop: "flex", mobile: "none" } })}
          />
          <div
            className={sprinkles({
              display: "flex",
              flex: "1",
              justifyContent: "flex-end",
              gap: { tablet: 20, mobile: 10 },
            })}
          >
            <InlineCta
              className={sprinkles({
                p: 10,
              })}
              {...navigation.searchLink}
            >
              <Icon
                height={18}
                name="Search"
                theme={{ mobile: isWhiteIcon ? "white" : "blue-deep", tablet: "blue-deep" }}
              />
            </InlineCta>
            <ClientAccountCta
              sprinklesCss={{
                display: { mobile: "none", desktop: "block" },
                p: 10,
              }}
              navigation={navigation}
            />
            <CartCta className={sprinkles({ p: 10, cursor: "pointer" })} />
            <BurgerCta
              navigation={navigation}
              className={sprinkles({
                display: { desktop: "none" },
                p: 10,
              })}
            />
          </div>
        </header>

        <DesktopPanels links={navigation.links} />
      </div>
    </nav>
  )
}
