"use client"

import { useEffect } from "react"
import { useSearchParams } from "next/navigation"
import { useHydrateAtoms } from "jotai/utils"

import useGetCart from "~/hooks/cart/useGetCart"
import { CART_OPEN_VALUE, CART_PARAM } from "~/components/ui/Product/ProductHeader/_data/constants"
import { Cart } from "~/components/globals/Cart"
import type { TCart } from "~/managers/CartManager/_data/serializer"
import { cartAtom } from "~/managers/CartManager/store"
import { usePanel } from "~/managers/PanelManager"

type CartHydrateProps = {
  cartFromServer: TCart | null
}

export function CartHydrate({ cartFromServer }: CartHydrateProps) {
  const { add } = usePanel()
  const searchParams = useSearchParams()
  const { cartQueryIsComplete } = useGetCart()

  const isCartOpen = searchParams.get(CART_PARAM) === CART_OPEN_VALUE
  useHydrateAtoms([[cartAtom, cartFromServer]])

  useEffect(() => {
    if (isCartOpen && cartQueryIsComplete) {
      add(<Cart />)
    }
  }, [isCartOpen, cartQueryIsComplete])

  return null
}
