"use client"

import { useState } from "react"

import { ALERT_CODES } from "~/lib/constants"
import { useShopifyNewsletterMutation } from "~/lib/shopify/hooks/useShopifyNewsletterMutation"
import { useAlerts } from "~/hooks/useAlerts"
import { EmailAndPrivacyForm } from "~/components/ui/EmailAndPrivacyForm"
import { Link } from "~/components/ui/Link"
import type { TNewsletterForm } from "~/components/ui/NewsletterForm/_data/serializer"
import RichText from "~/components/abstracts/RichText"
import { Stack, type StackProps } from "~/components/abstracts/Stack"
import { useTracking } from "~/providers/GTMTrackingProvider/hooks/use-tracking"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import RecaptchaHandler from "~/providers/RecaptchaProvider/RecaptchaHandler"

import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type NewsletterFormProps = {
  onSuccess?: () => void
  titleClassName?: string
  formData: NonNullable<TNewsletterForm>
  tags?: string[]
} & StackProps

export function NewsletterForm({ onSuccess, titleClassName, tags, formData, ...props }: NewsletterFormProps) {
  const t = useTranslate()
  const triggerAlert = useAlerts()
  const { sendEvent } = useTracking()
  const [result, setResult] = useState<string | null>(null)

  const { mutate: newsletterMutation, isLoading } = useShopifyNewsletterMutation(tags, {
    onSuccess: ({ data }) => {
      setResult(t("form_newsletter_success"))
      triggerAlert(ALERT_CODES.NEWSLETTER_SUBSCRIBE)
      if (data?.customer?.id) {
        sendEvent("newsletter", { user_id: data.customer.id })
      }

      onSuccess?.()
    },
    onError: () => {
      triggerAlert(ALERT_CODES.SERVER_ERROR)
    },
  })

  return (
    <Stack direction="column" {...props} data-comp="UI/NewsletterForm">
      <RichText className={titleClassName} render={formData.title} />
      <p
        className={text({
          design: "riposte-13",
          lineHeight: 1.5,
          color: "blue-light-txt",
        })}
      >
        {formData.description}&thinsp;
        <Link className={text({ color: "blue-deep" })} {...formData.linkToPrivacyPolicy} />
      </p>
      <RecaptchaHandler>
        <EmailAndPrivacyForm
          onEmailChange={() => {
            if (result) {
              setResult(null)
            }
          }}
          isLoading={isLoading}
          formData={formData}
          onSubmit={newsletterMutation}
          action="newsletter"
        />

        {result && <p className={css.result}>{result}</p>}
      </RecaptchaHandler>
    </Stack>
  )
}

export default NewsletterForm
