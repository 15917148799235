import { useEffect, useRef, useState, type DependencyList } from "react"
import { useIsomorphicLayoutEffect } from "framer-motion"

// TODO : Hide when not in view and add ref
export const useIntervalWithVisibilityCheck = (callback: () => void, delay: number | null, ...deps: DependencyList) => {
  const savedCallback = useRef(callback)
  const originalDelay = useRef(delay)
  const currentIntervalId = useRef<NodeJS.Timeout | null>(null)

  const [isTabHidden, setIsTabHidden] = useState(false)

  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const checkedIntervalID = currentIntervalId.current ? currentIntervalId.current : undefined

    if (isTabHidden || delay !== originalDelay.current) {
      clearInterval(checkedIntervalID)
      return
    }

    if (!delay && delay !== 0) {
      return
    }

    currentIntervalId.current = setInterval(() => savedCallback.current(), delay)

    return () => {
      clearInterval(checkedIntervalID)
    }
  }, [delay, isTabHidden, ...deps])

  useEffect(() => {
    document.addEventListener("visibilitychange", function () {
      setIsTabHidden(document.hidden)
    })
  }, [])
}
