import { useParams } from "next/navigation"

import { getLang } from "~/lib/i18n/utils/get-i18n"
import { getProcessedLocale } from "~/lib/i18n/utils/get-processed-locale"
import type { ALLOWED_NEWSLETTER_TAGS } from "~/lib/shopify/constants"

export function useCustomerTags() {
  const params = useParams()
  const lang = getLang(params.locale)

  const locale = getProcessedLocale(params?.locale)

  // Old tags backup
  // `language:${lang}`, `currency:${currency}`, `market:${market}`,

  const customerTags: `${(typeof ALLOWED_NEWSLETTER_TAGS)[number]}:${string}`[] = [`locale:${locale}`]

  return { lang, customerTags }
}
