"use client"

import { type ComponentProps } from "react"

import { useMobileTransparentNavigation } from "~/hooks/useMobileTransparentNavigation"
import Icon from "~/components/abstracts/Icon"
import { AbstractMobileToggleButton } from "~/components/abstracts/Navigation/MobileToggleButton"
import type { TNavigation } from "~/components/globals/Navigation/_data/serializer"
import { MobilePanel } from "~/components/globals/Navigation/components/Mobile/ClientMobilePanel"
import { usePanel } from "~/managers/PanelManager"

import { sprinkles } from "~/styles/sprinkles.css"

type BurgerCtaProps = {
  navigation: NonNullable<TNavigation>
} & ComponentProps<"button">

export function BurgerCta({ className, navigation, ...props }: BurgerCtaProps) {
  const { add, removeCurrent } = usePanel()
  const { isWhiteIcon } = useMobileTransparentNavigation()

  return (
    <AbstractMobileToggleButton
      onOpen={() => {
        add(<MobilePanel navigation={navigation} />)
      }}
      onClose={() => removeCurrent()}
      className={className}
      {...props}
    >
      {{
        open: <Icon height={18} name={"Cross"} />,
        close: (
          <Icon
            className={sprinkles({ transition: "color-0.4s-ease" })}
            height={18}
            name={"Burger"}
            theme={{ mobile: isWhiteIcon ? "white" : "blue-deep", tablet: "blue-deep" }}
          />
        ),
      }}
    </AbstractMobileToggleButton>
  )
}
