"use client"

import { useState } from "react"
import { useInterval } from "usehooks-ts"

import { useSetCookiesConsentDone } from "~/managers/CookiesConsentManager/hooks"

declare global {
  interface Window {
    _axcb?: any[]
  }
}

const TRIGGER_DELAY = 100

export function CookiesConsentHandler() {
  const [isLoaded, setIsLoaded] = useState(false)

  const setCookiesConsentDone = useSetCookiesConsentDone()

  useInterval(
    () => {
      if (window._axcb && window._axcb.hasOwnProperty("push")) {
        window._axcb.push(function (axeptio: any) {
          axeptio.on("cookies:complete", function () {
            setCookiesConsentDone(true)
          })
        })
        setIsLoaded(true)
      }
    },
    isLoaded ? null : TRIGGER_DELAY
  )

  return null
}
