"use client"

import type { ComponentProps } from "react"
import clsx from "clsx"

import InlineCta from "~/components/ui/InlineCta"
import type { TInlineCta } from "~/components/ui/InlineCta/_data/serializer"
import { LangSwitcher } from "~/components/ui/LangSwitcher"
import Icon, { type IconProps } from "~/components/abstracts/Icon"
import { NavigationLogo } from "~/components/globals/Navigation/components/ClientNavigationLogo"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import { sprinkles } from "~/styles/sprinkles.css"
import { showFrom } from "~/styles/utils/show-hide"

import * as css from "./styles.css"

export type BottomFooterProps = {
  socials: { link: TInlineCta; name: IconProps["name"] }[]
} & ComponentProps<"div">

const ICON_SIZE = 20

function BottomFooter({ className, socials }: BottomFooterProps) {
  const t = useTranslate()

  return (
    <div className={clsx(className, css.BottomFooter)}>
      <div className={sprinkles({ flex: "1" })}>
        <NavigationLogo logoAlt={t("aria_logo_alt")} />
      </div>

      <div className={sprinkles({ display: "flex", flexDirection: "column", alignItems: "center" })}>
        <div className={sprinkles({ display: "flex", alignItems: "center" })}>
          {socials.map((social, i) => (
            <InlineCta
              key={`social-link-${i}`}
              aria-label={social.link.children}
              {...social.link}
              className={i < socials.length - 1 ? css.SocialLink : undefined}
            >
              <Icon width={ICON_SIZE} height={ICON_SIZE} name={social.name} />
            </InlineCta>
          ))}
        </div>
        <div className={css.madeBy}>
          <a href="https://unlikely.technology" target="blank">
            tech by Unlikely Technology
          </a>
          <br />
          <a href="https://numbered.com" target="blank" className={css.madeLink}>
            design by Numbered
          </a>
        </div>
      </div>

      <div className={showFrom("tablet", "flex", { flex: "1", justifyContent: "flex-end" })}>
        <LangSwitcher />
      </div>
    </div>
  )
}

export default BottomFooter
