"use client"

import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import type { Sizes } from "~/components/abstracts/Image"
import { AbstractDesktopPanel } from "~/components/abstracts/Navigation/DesktopPanel"
import type { TNavigation } from "~/components/globals/Navigation/_data/serializer"
import { PanelBigLinks } from "~/components/globals/Navigation/components/PanelBigLinks"
import { PanelLinksWithTitle } from "~/components/globals/Navigation/components/PanelLinksWithTitle"
import { PanelPushImage } from "~/components/globals/Navigation/components/PanelPushImage"
import type { TPushImage } from "~/components/globals/Navigation/components/PanelPushImage/_data/serializer"
import { SubNavLink } from "~/components/globals/Navigation/components/SubNavLink"
import { useCurrentPanelIndex } from "~/managers/NavigationManager"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

type DesktopPanelProps = PropsWithClassName<{ links: NonNullable<TNavigation>["links"] }>

export function DesktopPanels({ className, links, ...props }: DesktopPanelProps) {
  const currentPanelIndex = useCurrentPanelIndex(links)

  return links.map((currentPanel, index) => (
    <AbstractDesktopPanel
      key={index}
      className={clsx(
        className,
        css.DesktopPanel,
        sprinkles({ display: currentPanelIndex === index ? "block" : "none" })
      )}
      {...props}
    >
      <div className={clsx(css.DesktopPanelBlock)}>
        {currentPanel.bigLinks.map((bigLink, index) => {
          return <PanelBigLinks gap={25} key={"PanelBigLinks" + index} links={bigLink.links} className={css.BigLinks} />
        })}

        {currentPanel.linksWithTitle.map(({ title, links }, index) => (
          <PanelLinksWithTitle key={"PanelLinksWithTitle" + index} title={title} className={css.LinksWithTitle}>
            {links.map((link, index) => {
              return (
                <SubNavLink
                  key={"LinksWithTitle" + index}
                  theme="blue-med"
                  link={link}
                  className={css.DesktopSubNavLink}
                />
              )
            })}
          </PanelLinksWithTitle>
        ))}

        {currentPanel.pushesImage.length > 0 && (
          <>
            {currentPanel.pushesImage.map((props, index) => {
              const { ratio, sizesFromBreakpoints, className } = getPushImageBySize(props.size)
              return (
                <PanelPushImage
                  key={"PanelPushImage" + index}
                  {...props}
                  ratio={ratio}
                  sizesFromBreakpoints={sizesFromBreakpoints}
                  className={clsx(className, css.PushImageDefault)}
                />
              )
            })}
          </>
        )}
      </div>
    </AbstractDesktopPanel>
  ))
}

function getPushImageBySize(size: TPushImage["size"]) {
  switch (size) {
    case "large":
      return {
        ratio: "83/80",
        sizesFromBreakpoints: [{ ratio: 1 / 4 }] as Sizes,
        className: css.PushImage.large,
      } as const
    case "medium":
      return {
        ratio: "53/64",
        sizesFromBreakpoints: [{ ratio: 1 / 5 }] as Sizes,
        className: css.PushImage.medium,
      } as const
    case "small":
      return {
        ratio: "5/8",
        sizesFromBreakpoints: [{ ratio: 1 / 6 }] as Sizes,
        className: css.PushImage.small,
      } as const
  }
}
