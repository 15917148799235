"use client"

import { useEffect } from "react"
import Script from "next/script"
import clsx from "clsx"

import { GORGIAS_ID } from "~/lib/constants"
import useChatBot from "~/hooks/useChatBot"
import Icon from "~/components/abstracts/Icon"
import { Spinner } from "~/components/abstracts/Spinner"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import * as css from "./styles.css"

export interface ChatButtonProps {
  disable?: boolean
}

function ChatButton({ disable }: ChatButtonProps) {
  const t = useTranslate()
  const { open, loading, loadScript, openChatBot } = useChatBot({ disable })
  // const tracking = useTracking()

  useEffect(() => {
    // TODO add tracking like
    // open &&
    // tracking.emit(TRACKING_EVENTS.EVENT_OPEN_CHAT, {
    //   action: "Click",
    //   label: "Open chatbot",
    // })
  }, [open])

  return (
    !disable && (
      <>
        {loadScript && (
          <Script
            strategy="lazyOnload"
            id="gorgias-chat-widget-install-v3"
            src={`https://config.gorgias.chat/bundle-loader/${GORGIAS_ID}`}
          />
        )}

        <div className={clsx(css.ChatButton)}>
          <div>
            <button
              aria-label={t(open ? "aria_chatbot_close" : "aria_chatbot_open")}
              className={clsx(css.Button, { isOpen: open })}
              onClick={() => openChatBot(!open)}
            >
              {loading && <Spinner size={20} color="#fff" />}
              {!loading && open && <Icon theme="white" height={20} width={20} name="Cross" />}
              {!loading && !open && <Icon theme="white" height={24} width={24} name="Chatbot" />}
            </button>
          </div>
        </div>
      </>
    )
  )
}

export default ChatButton
