export const OPTION_COLOR = "Color"
export const OPTION_SIZE = "Taille"
export const OPTION_CONFORT = "Confort"
export const OPTION_AMOUNT = "Montant"
export const UNIVERSAL_COLOR_OPTION_VALUE = "Universal"
export const BUNDLE_OPTIONS_SYNCED = [OPTION_SIZE]
export const EVERY_OPTIONS_WITHOUT_COLOR = [OPTION_SIZE, OPTION_CONFORT, OPTION_AMOUNT] as const
export const EVERY_OPTIONS = [OPTION_COLOR, OPTION_SIZE, OPTION_CONFORT, OPTION_AMOUNT] as const

export const OPTIONS_PARAM = "options"
export const COLOR_PARAM = "color"
export const SEARCH_PARAM = "q"
export const DEBUG_PARAM = "debug"
export const CART_PARAM = "cart"
export const CART_OPEN_VALUE = "open"
