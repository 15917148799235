import type { ComponentProps } from "react"

import { useOnRouteChange } from "~/hooks/useOnRouteChange"
import { usePanelIndex } from "~/components/abstracts/Navigation/hooks"

type AbstractDesktopPanelProps = ComponentProps<"div">

export function AbstractDesktopPanel({
  className,
  onMouseEnter,
  onMouseLeave,
  children,
  ...props
}: AbstractDesktopPanelProps) {
  const [currentPanelIndex, setPanelIndex] = usePanelIndex()

  useOnRouteChange(() => {
    setPanelIndex(null)
  })

  return (
    <div
      className={className}
      {...props}
      onMouseEnter={(event) => {
        onMouseEnter?.(event)
        setPanelIndex(currentPanelIndex)
      }}
      onMouseLeave={(event) => {
        onMouseLeave?.(event)
        setPanelIndex(null)
      }}
    >
      {children}
    </div>
  )
}
