import type { UnlikelyVariant } from "@unlikelystudio/commerce-connector"

import type { InferReturn, Nullish } from "~/@types/generics"
import { getVariantMetafieldValue } from "~/lib/shopify/constants"
import { parseTextsFromMetatield } from "~/lib/shopify/utils/parse-texts-from-metafields"
import serializePrice from "~/data/price/serializer"
import { serializeShopifyImages } from "~/components/ui/Image/_data/serializer"
import { fetchColorDotImages } from "~/components/ui/Product/ProductHeader/_data/utils/fetch-dot-images"

export type TVariant = InferReturn<typeof serializeVariants>[number]

export async function serializeVariants(variants: Nullish<UnlikelyVariant[]>, locale: Nullish<string>) {
  if (!variants || variants.length === 0) {
    return []
  }

  const dotImages = await fetchColorDotImages(variants)

  const serializedVariants = variants.map((variant) => {
    const variantDotImageID = getVariantMetafieldValue(variant.metafields, "COLOR_DOT_IMAGE")
    const variantColorTag = getVariantMetafieldValue(variant.metafields, "COLOR_TAG")
    const hiddenOnMarket = getVariantMetafieldValue(variant.metafields, "HIDDEN_ON_MARKET")
    const foundDotImage =
      dotImages.find((dotImage) => dotImage && dotImage.gid === variantDotImageID)?.image?.[0] ?? null

    return {
      id: variant.id,
      title: variant.title,
      availableForSale: variant.availableForSale,
      sku: variant.sku,
      price: serializePrice({
        locale,
        amount: variant.price?.amount,
        currencyCode: variant.price?.currencyCode,
      }),
      unserializedPrice: variant.price,
      compareAtPrice:
        (variant.compareAtPrice?.amount ?? 0) > 0
          ? serializePrice({
              locale,
              amount: variant.compareAtPrice?.amount,
              currencyCode: variant.compareAtPrice?.currencyCode,
            })
          : null,
      unserializedCompareAtPrice: variant.compareAtPrice,
      colorDotImage: foundDotImage,
      selectedOptions: variant.selectedOptions,
      image: variant.image ? serializeShopifyImages([variant.image]) : null,
      hiddenOnMarket: parseTextsFromMetatield(hiddenOnMarket) ?? [],
      colorTag: variantColorTag ?? null,
      quantityAvailable: variant.quantityAvailable,
    }
  })

  return serializedVariants
}
