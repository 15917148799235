import Cookies from "js-cookie"

import type { Nullish } from "~/@types/generics"
import { COOKIE_KEYS } from "~/lib/constants"

export default function useMarketCookie() {
  const POPIN_MARKET_HAS_INTERACTED = COOKIE_KEYS.popinMarketHasInteracted
  const POPIN_MARKET_COUNTRY_CODE = COOKIE_KEYS.popinMarketCountryCode
  const VERCEL_COUNTRY_CODE = COOKIE_KEYS.vercelCountryCode

  function setPopinMarketCountryCode(countryCode: string) {
    Cookies.set(POPIN_MARKET_COUNTRY_CODE, countryCode, { expires: 365 })
  }

  function setPopinHasInteracted(value: boolean) {
    Cookies.set(POPIN_MARKET_HAS_INTERACTED, JSON.stringify(value), { expires: 365 })
  }

  function getPopinMarketCountryCode() {
    const cookie = Cookies.get(POPIN_MARKET_COUNTRY_CODE)
    return cookie ? (cookie as Nullish<string>) : null
  }

  function getPopinHasInteracted() {
    const cookie = Cookies.get(POPIN_MARKET_HAS_INTERACTED)
    return cookie ? (JSON.parse(cookie) as boolean) ?? false : false
  }

  function getVercelCountryCode() {
    const cookie = Cookies.get(VERCEL_COUNTRY_CODE)
    return cookie ? (cookie as Nullish<string>) : null
  }

  return {
    setPopinMarketCountryCode,
    getPopinMarketCountryCode,
    setPopinHasInteracted,
    getPopinHasInteracted,
    getVercelCountryCode,
  }
}
