import type { Nullish } from "~/@types/generics"

const uniq = <T extends Nullish<string | number>>(array: T[]): T[] => {
  return Array.from(new Set(array))
}

const uniqBy = <T>(array: T[], key: keyof T): T[] => {
  return array.reduce<T[]>((acc, item) => {
    if (!acc?.find((uniqueItem) => uniqueItem?.[key] === item?.[key])) {
      acc.push(item)
    }
    return acc
  }, [])
}

export { uniqBy, uniq }
