"use client"

import path from "path"
import { useRef, useState } from "react"
import { usePathname, useSearchParams } from "next/navigation"
import clsx from "clsx"
import { useForm } from "react-hook-form"

import { i18nConfig } from "~/config/i18n-config"
import { DEFAULT_LOCALE } from "~/lib/i18n/constants"
import { useLocale } from "~/lib/i18n/hooks/useLocale"
import { getLang, getMarketByCountry } from "~/lib/i18n/utils/get-i18n"
import { getPathnameWithoutLocale } from "~/lib/i18n/utils/get-pathname-without-locale"
import { getSplittedPathname } from "~/lib/i18n/utils/get-splitted-pathname"
import useMarketCookie from "~/lib/shopify/hooks/useMarketCookie"
import { getSfProduct } from "~/lib/shopify/queries/get-sf-product"
import { handleZodSafeParse } from "~/lib/zod/utils/handle-zod-safe-parse"
import { Form, FormControl, FormField, FormItem } from "~/components/ui/Form"
import { linkSchema } from "~/components/ui/Link/_data/schema"
import serializeLink from "~/components/ui/Link/_data/serializer"
import RoundedCta from "~/components/ui/RoundedCta"
import { Select } from "~/components/ui/Select"
import { Stack } from "~/components/abstracts/Stack"
import { getAllCountriesOptions } from "~/components/globals/PopinMarket/utils/get-all-countries-options"
import { getLanguagesOptionsByCountry } from "~/components/globals/PopinMarket/utils/get-languages-options-by-country"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { useLanguages } from "~/managers/LanguagesManager"
import { Popin, usePopin } from "~/managers/PopinManager"
import getAlternateUrl from "~/utils/alternate-url"
import { applySearchParams } from "~/utils/apply-searchparams"
import { capitalize } from "~/utils/capitalize"

import { sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

export function PopinMarket({ country, lang }: { country?: string; lang?: string }) {
  const [isLoading, setIsLoading] = useState(false)

  const popinRef = useRef(null)
  const t = useTranslate()
  const pathname = usePathname()
  const locale = useLocale()
  const searchParams = useSearchParams()
  const { removeCurrent } = usePopin()

  const { getPopinMarketCountryCode, setPopinMarketCountryCode, setPopinHasInteracted } = useMarketCookie()

  const allCountriesOptions = getAllCountriesOptions()

  function onRemove() {
    setPopinHasInteracted(true)
  }

  const langByLocale = getLang(locale)
  const defaultCountry = country ?? getPopinMarketCountryCode() ?? allCountriesOptions[0]?.value

  const defaultLanguages = getLanguagesOptionsByCountry(defaultCountry ?? "")
  const defaultLang =
    lang ?? defaultLanguages.find((langOption) => langOption.value === langByLocale)
      ? langByLocale
      : defaultLanguages[0]?.value

  const form = useForm<{ country: string; lang: string }>({
    defaultValues: {
      country: defaultCountry,
      lang: defaultLang,
    },
  })

  const languages = useLanguages()[0]

  const { control, setValue, watch } = form

  function handlePopinClose(selectedCountry: string) {
    onRemove()
    removeCurrent()
    setPopinMarketCountryCode(selectedCountry)
  }

  const onSubmit = form.handleSubmit(async ({ country: selectedCountry, lang: selectedLang }) => {
    const newLocale =
      Object.entries(i18nConfig).find(([_, value]) => {
        return getMarketByCountry(selectedCountry) === value.market && selectedLang === value.lang
      })?.[0] ?? DEFAULT_LOCALE

    if (locale === newLocale) {
      handlePopinClose(selectedCountry)
      return
    }

    const splittedPathname = getSplittedPathname(pathname)
    const pathnameWithoutLocale = getPathnameWithoutLocale(pathname)

    const currentSlug = splittedPathname[2]

    let newHref

    if (currentSlug && languages) {
      if ("shopifyProductId" in languages) {
        const id = languages?.shopifyProductId

        if (!id) {
          return
        }

        setIsLoading(true)

        const { product } = await getSfProduct({
          locale: newLocale,
          id,
        })

        setIsLoading(false)

        const parsedLink = handleZodSafeParse(linkSchema, product)
        const link = parsedLink ? serializeLink(parsedLink, newLocale, false) : null
        newHref = link?.href
      } else {
        const parsedLink = handleZodSafeParse(linkSchema, { linktype: "story", story: languages })
        const link = parsedLink ? serializeLink(parsedLink, newLocale, false) : null
        newHref = link?.href
      }
    }

    window.location.href = getAlternateUrl(
      newHref ?? path.join("/", newLocale, applySearchParams(pathnameWithoutLocale, searchParams))
    )
    handlePopinClose(selectedCountry)
  })

  return (
    <Popin clickOutsideRef={popinRef} onRemove={onRemove}>
      <div className={css.PopinMarket} ref={popinRef}>
        <Popin.CloseButton onRemove={onRemove} />
        <h3
          className={clsx(
            text({
              design: "spezia-24",
              transform: "uppercase",
              weight: "semiBold",
              lineHeight: 1,
              letterSpacing: 0.6,
              color: "blue-deep",
            }),
            sprinkles({
              mB: 30,
            })
          )}
        >
          {t("market_title")}
        </h3>

        <Form {...form}>
          <form onSubmit={onSubmit}>
            <Stack gap={20} direction="column">
              <FormField
                control={control}
                name="country"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Select
                        errorBackgroundColor="white"
                        onChange={(event) => {
                          field.onChange(event)

                          const newCountry = event.currentTarget.value
                          setValue("lang", getLanguagesOptionsByCountry(newCountry)[0]?.value ?? "")
                        }}
                        value={field.value}
                      >
                        {{
                          label: t("market_country_label"),
                          options: allCountriesOptions.map((option, i) => {
                            return (
                              <option value={option.value} key={`lang-switcher-option-${i}`}>
                                {option.label}
                              </option>
                            )
                          }),
                        }}
                      </Select>
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="lang"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Select errorBackgroundColor="white" {...field}>
                        {{
                          label: t("market_language_label"),
                          options: getLanguagesOptionsByCountry(watch("country")).map((option, i) => {
                            return (
                              <option value={option.value} key={`lang-switcher-option-${i}`}>
                                {capitalize(option.label)}
                              </option>
                            )
                          }),
                        }}
                      </Select>
                    </FormControl>
                  </FormItem>
                )}
              />
            </Stack>

            <RoundedCta
              className={sprinkles({ mT: 30 })}
              size="large"
              width="full"
              theme="blue-deep"
              type="primary"
              htmlType="submit"
              isLoading={isLoading}
            >
              {t("market_submit_label")}
            </RoundedCta>
          </form>
        </Form>
      </div>
    </Popin>
  )
}
