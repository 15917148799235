"use client"

import { useParams } from "next/navigation"

import { getProcessedLocale } from "~/lib/i18n/utils/get-processed-locale"
import { useMobileTransparentNavigation } from "~/hooks/useMobileTransparentNavigation"
import { Link } from "~/components/ui/Link"
import { getPath } from "~/components/ui/Link/utils/href-resolver"
import Icon from "~/components/abstracts/Icon"

import { sprinkles } from "~/styles/sprinkles.css"

type NavigationLogoProps = {
  logoAlt: string
}

export function NavigationLogo({ logoAlt }: NavigationLogoProps) {
  const params = useParams()
  const { isWhiteIcon } = useMobileTransparentNavigation()

  return (
    <Link className={sprinkles({ display: "inline-flex" })} href={getPath(getProcessedLocale(params.locale), "/", {})}>
      <Icon
        name="Logo"
        className={sprinkles({ transition: "color-0.4s-ease" })}
        height={22}
        title={logoAlt}
        theme={{ mobile: isWhiteIcon ? "white" : "blue-deep", tablet: "blue-deep" }}
      />
    </Link>
  )
}
