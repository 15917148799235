import type { Nullish } from "~/@types/generics"
import { isGID } from "~/lib/shopify/utils/id"

export function parseShopifyReferencesFromMetatield(stringifiedReferences: Nullish<string>) {
  if (!stringifiedReferences) {
    return null
  }

  const parsedReferences = JSON.parse(stringifiedReferences)

  if (!Array.isArray(parsedReferences)) {
    return null
  }

  return parsedReferences.map((v) => (isGID(v) ? v : null)).filter(Boolean)
}
