"use client"

import { useState } from "react"
import clsx from "clsx"

import Slider, { useSliderState } from "@unlikelystudio/react-slider"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import { useIntervalWithVisibilityCheck } from "~/hooks/useIntervalWithVisibilityCheck"
import RichText from "~/components/ui/RichText"
import type { SbRichText } from "~/components/ui/RichText/_data/schema"
import { SliderBullets } from "~/components/ui/Slider/SliderBullets"
import { Stack } from "~/components/abstracts/Stack"

import { sprinkles } from "~/styles/sprinkles.css"
import { hideFrom, showFrom } from "~/styles/utils/show-hide"
import { grid } from "~/styles/variants"

import type { NumberOfItemsVariants } from "./styles.css"
import * as css from "./styles.css"

type Items = {
  title: Nullish<SbRichText>
  text: Nullish<SbRichText>
}

export type TopFooterProps = {
  items: Items[]
} & PropsWithClassName

const INTERVAL_DELAY = 3500
const MAX_ITEMS = 4

function TopFooter({ items }: TopFooterProps) {
  const slicedItems = items.slice(0, MAX_ITEMS)
  const numberOfItems = slicedItems.length as NumberOfItemsVariants
  const [{ nextSlide, slideIndex, maxSlideIndex }, setSliderState] = useSliderState()

  const [isDragging, setIsDragging] = useState(false)
  const delay = isDragging ? 200000 : INTERVAL_DELAY

  useIntervalWithVisibilityCheck(nextSlide, delay)

  const onDragStart = () => {
    setIsDragging(true)
  }

  const onDragEnd = () => {
    setIsDragging(false)
  }

  return (
    <div className={clsx(css.TopFooter, sprinkles({ overflow: "hidden" }))}>
      <div className={clsx(showFrom("tablet", "block"))}>
        <div className={clsx(css.wrapper, grid({ type: "base" }))}>
          {slicedItems.map((item, index) => (
            <Stack
              direction="column"
              key={`top-footer-${index}`}
              className={clsx(css.NumberOfItems[numberOfItems])}
              gap={10}
            >
              <RichText className={css.Title} render={item.title} />
              <RichText className={css.Text} render={item.text} />
            </Stack>
          ))}
        </div>
      </div>
      <Slider
        dragProps={{
          onDragStart,
          onDragEnd,
        }}
        setSliderState={setSliderState}
        snap
        infinite
        autoBlockSlideIndexChange
        maxSlideIndexChange={1}
        className={clsx(css.Slider, hideFrom("tablet", "block"))}
      >
        {slicedItems.map((item, index) => (
          <Stack direction="column" key={`top-footer-${index}`} className={css.Item} gap={10}>
            <RichText className={css.Title} render={item.title} />
            <RichText className={css.Text} render={item.text} />
          </Stack>
        ))}
      </Slider>
      <div className={clsx(css.dots, hideFrom("tablet", "flex", { mT: 20 }))}>
        <SliderBullets
          className={css.sliderBullets}
          sprinklesCss={{ mX: "auto" }}
          currentIndex={slideIndex}
          totalItems={maxSlideIndex + 1}
          gap={8}
          bulletSprinkles={{ borderRadius: 2 }}
        />
      </div>
    </div>
  )
}

export default TopFooter
