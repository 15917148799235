import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"

// import { getProductMetafieldValue } from "~/lib/shopify/constants"
// import { getIsBundleProduct } from "~/components/ui/Product/utils/get-is-bundle-product"

export function getProductTitle(product: Pick<UnlikelyProduct, "title">) {
  // const isProductBundle = getIsBundleProduct(product)
  // const bundleOverridedTitle = getProductMetafieldValue(product.metafields, "BUNDLE_OVERRIDED_TITLE")

  return product.title
}
