import { i18nConfig } from "~/config/i18n-config"
import { getIntl, getLang, getMarketByCountry } from "~/lib/i18n/utils/get-i18n"

export function getLanguagesOptionsByCountry(selectedCountry: string) {
  const localesByCountry = Object.entries(i18nConfig)
    .filter(([_, value]) => {
      return getMarketByCountry(selectedCountry) === value.market
    })
    .map(([locale]) => locale)

  return localesByCountry
    .flatMap((locale) => {
      const intl = getIntl(locale)
      const lang = getLang(locale)

      const languageNames = new Intl.DisplayNames(intl, { type: "language" })

      const label = languageNames.of(lang)
      if (!label) {
        return []
      }

      return {
        label,
        value: lang,
      }
    })
    .sort((a, b) => {
      return a.label?.localeCompare(b.label)
    })
}
