import type { Nullish } from "~/@types/generics"
import { getI18nKey } from "~/lib/i18n/utils/get-i18n"

export default function serializePrice({
  locale,
  currencyCode,
  amount,
  additionalOptions,
}: {
  locale: Nullish<string>
  currencyCode: Nullish<string>
  amount: Nullish<number>
  additionalOptions?: Intl.NumberFormatOptions
}) {
  const shopifyISOCodeForTransactionsWithoutCurrency = "XXX"
  const options = {
    style: "currency",
    /**
     * Prevent XXX currency code from being displayed. XXX is use by Shopify
     * to denote transactions that involve no currency.
     * For more details : https://shopify.dev/changelog/new-code-for-unrecognized-currencies#:~:text=XXX%20is%20the,an%20input%20value.
     */
    currency: currencyCode === shopifyISOCodeForTransactionsWithoutCurrency ? "EUR" : currencyCode ?? undefined,
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...additionalOptions,
  }

  const numberAmount = Number(amount)

  if (!locale || !currencyCode || (numberAmount !== 0 && !numberAmount)) {
    return null
  }

  const intl = getI18nKey(locale, "intl")

  return (
    new Intl.NumberFormat(intl, options)
      .format(Number(amount))
      // Remove unbreakable space
      .replace(/\u00a0/g, "")
  )
}
