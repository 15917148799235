import(/* webpackMode: "eager" */ "/vercel/path0/components/globals/Footer/BottomFooter/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/globals/Footer/MiddleFooter/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/globals/Footer/TopFooter/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/globals/Navigation/components/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/globals/USPBanner/index.client.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/ChatButton/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/StoryblokPreviewToaster/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/managers/CartManager/CartHydrate.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/managers/CookiesConsentManager/CookiesConsentHandler.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/managers/InternalRedirectsManager/ClientInternalRedirectsManager/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../public/fonts/riposte/riposte-medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../public/fonts/riposte/riposte-light.woff2\",\"weight\":\"300\",\"style\":\"normal\"}],\"variable\":\"--font-riposte\"}],\"variableName\":\"riposte\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../public/fonts/spezia/spezia-semibold.woff2\",\"weight\":\"600\",\"style\":\"normal\"}],\"variable\":\"--font-spezia\"}],\"variableName\":\"spezia\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/providers/ClientAppProvider/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/providers/I18nProvider/ClientProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/styles/global.css.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/styles/reset.css.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/styles/sprinkles.css.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/styles/theme.css.ts")