import { type UnlikelyVariant } from "@unlikelystudio/commerce-connector"

import { getVariantMetafieldValue } from "~/lib/shopify/constants"
import { getSfMedias } from "~/lib/shopify/queries/get-sf-medias"
import { serializeShopifyImages } from "~/components/ui/Image/_data/serializer"
import { uniq } from "~/utils/uniq"

async function fetchColorDotImages(variants: UnlikelyVariant[] | null) {
  if (!variants) {
    return []
  }

  const imagesIDs = variants
    ? variants
        .map((variant) => {
          return getVariantMetafieldValue(variant.metafields, "COLOR_DOT_IMAGE")
        })
        .flat()
        .filter(Boolean)
    : []

  const imagesData = imagesIDs ? (await getSfMedias({ withLocale: false, ids: uniq(imagesIDs) })).medias : []

  return (
    uniq(imagesIDs)
      ?.map((id, index) => {
        const currentImage = imagesData?.[index]

        return currentImage
          ? {
              gid: id,
              image: serializeShopifyImages([currentImage]),
            }
          : null
      })
      ?.filter(Boolean) ?? []
  )
}

export { fetchColorDotImages }
