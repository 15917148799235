import { env } from "~/env"

import { usePanelIndex } from "~/components/abstracts/Navigation/hooks"
import type { TNavigation } from "~/components/globals/Navigation/_data/serializer"

// Replace : null by ex: : 0, : 1 to see the panels
export const debugModeIndex: number | null = env.NEXT_PUBLIC_NODE_ENV === "production" ? null : null

export function usePanelDisplayed(links: NonNullable<TNavigation>["links"]) {
  const [currentPanelIndex] = usePanelIndex()

  if (debugModeIndex !== null) {
    return (
      (links[debugModeIndex]?.bigLinks?.length ?? 0) > 0 ||
      (links[debugModeIndex]?.linksWithTitle?.length ?? 0) > 0 ||
      (links[debugModeIndex]?.pushesImage?.length ?? 0) > 0
    )
  }

  if (currentPanelIndex === null) {
    return false
  }

  return (
    (links[currentPanelIndex]?.bigLinks?.length ?? 0) > 0 ||
    (links[currentPanelIndex]?.linksWithTitle?.length ?? 0) > 0 ||
    (links[currentPanelIndex]?.pushesImage?.length ?? 0) > 0
  )
}

export function useCurrentPanelIndex(links: NonNullable<TNavigation>["links"]) {
  const [currentPanelIndex] = usePanelIndex()
  const displayedPanel = usePanelDisplayed(links)

  if (debugModeIndex !== null && displayedPanel) {
    return debugModeIndex
  }

  if (currentPanelIndex === null || !displayedPanel) {
    return null
  }

  return currentPanelIndex
}
