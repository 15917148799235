"use client"

import clsx from "clsx"

import RoundedCta from "~/components/ui/RoundedCta"
import { AbstractStoryblokPreviewToaster } from "~/components/abstracts/StoryblokPreviewToaster"

import { sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

export interface StoryblokPreviewToasterProps {
  className?: string
  enabled: boolean
}

function StoryblokPreviewToaster({ className, enabled }: StoryblokPreviewToasterProps) {
  return (
    <AbstractStoryblokPreviewToaster
      className={clsx(css.StoryblokPreviewToaster, className)}
      data-comp="UI/StoryblokPreviewToaster"
      titleClassName={clsx(text({ design: "riposte-14", weight: "medium" }), sprinkles({ mB: 5 }))}
      title="Storyblok Preview Mode"
      enabled={enabled}
    >
      {({ onClose, onExit }) => (
        <>
          <RoundedCta className={text({ color: "blue-deep" })} onClick={onClose}>
            Close
          </RoundedCta>
          <RoundedCta className={sprinkles({ mL: 5 })} theme="blue-deep" onClick={onExit}>
            Exit preview
          </RoundedCta>
        </>
      )}
    </AbstractStoryblokPreviewToaster>
  )
}

export default StoryblokPreviewToaster
