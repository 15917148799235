import { getAllCountries } from "~/lib/i18n/utils/get-all-countries"
import { getIntl, getLocaleByCountry } from "~/lib/i18n/utils/get-i18n"

export function getAllCountriesOptions() {
  const allCountries = getAllCountries()

  return allCountries
    .flatMap((country) => {
      const locale = getLocaleByCountry(country)
      const intl = getIntl(locale)
      const regionNames = new Intl.DisplayNames(intl, { type: "region" })

      const label = regionNames.of(country)
      if (!label) {
        return []
      }

      return {
        label,
        value: country,
      }
    })
    .sort((a, b) => {
      return a.label?.localeCompare(b.label)
    })
}
