"use client"

import { Alerts } from "@unlikelystudio/react-abstract-components"

import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import * as css from "./styles.css"

export function CustomAlerts() {
  const t = useTranslate()

  const ANIMATE = {
    opacity: [0, 1, 1, 0],
    y: ["100%", "0%", "0%", "0%"],
    transition: {
      duration: 3,
      times: [0, 0.1, 0.9, 1],
      ease: ["easeOut", "linear", "linear"],
    },
  }

  return (
    <Alerts
      className={css.Alerts}
      alertClassName={css.Alert}
      closeButtonClassName={css.CloseIcon}
      titleClassName={css.title}
      textClassName={css.paragraph}
      closeLabel={t("aria_popin_close")}
      animate={ANIMATE}
    />
  )
}
