import type { ComponentProps } from "react"

import type InlineCta from "~/components/ui/InlineCta"
import { Stack, type StackProps } from "~/components/abstracts/Stack"
import type { TNavigation } from "~/components/globals/Navigation/_data/serializer"
import { SubNavLink } from "~/components/globals/Navigation/components/SubNavLink"

type PanelBigLinksProps = {
  links: NonNullable<TNavigation>["links"][number]["bigLinks"][number]["links"]
  inlineCtaProps?: ComponentProps<typeof InlineCta>
} & StackProps

export function PanelBigLinks({
  links,
  gap = 25,
  inlineCtaProps = {},
  className,
  sprinklesCss: sprinkles,
  ...props
}: PanelBigLinksProps) {
  return (
    <Stack
      sprinklesCss={{ alignItems: "flex-start", ...sprinkles }}
      direction="column"
      gap={gap}
      className={className}
      {...props}
    >
      {links.map((link, index) => {
        return <SubNavLink key={"PanelBigLinks" + index} link={link} type="large" {...inlineCtaProps} />
      })}
    </Stack>
  )
}
