"use client"

import { useEffect, useState } from "react"
import clsx from "clsx"
import useMeasure from "react-use-measure"

import { useLocale } from "~/lib/i18n/hooks/useLocale"
import RichText from "~/components/ui/RichText"
import Icon from "~/components/abstracts/Icon"
import type { TUSPBanner } from "~/components/globals/USPBanner/_data/serializer"
import { isAllowedToShowUSP, setUSPClosedCookie } from "~/components/globals/USPBanner/hooks"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { useUSPBannerHeight } from "~/managers/USPBannerManager"

import { grid } from "~/styles/variants"

import * as css from "./styles.css"

type USPBannerComponentProps = {
  USPBanner: NonNullable<TUSPBanner>
}

export default function USPBannerClient({ USPBanner }: USPBannerComponentProps) {
  const t = useTranslate()
  const locale = useLocale()

  const [displayed, setDisplayed] = useState(true)

  const setUspBannerHeight = useUSPBannerHeight()[1]
  const [measureRef, measure] = useMeasure()

  useEffect(() => {
    setUspBannerHeight(measure.height)
  }, [measure.height, setUspBannerHeight])

  function handleClose() {
    setDisplayed(false)
    setUSPClosedCookie(locale)
  }

  const displayBanner = isAllowedToShowUSP(locale) && displayed

  return displayBanner ? (
    <div className={grid({ type: "fluid" })} ref={measureRef} data-comp="UI/USPBanner">
      <div className={css.USPBanner}>
        <RichText className={clsx(css.wrapper)} render={USPBanner.text} />
        <button aria-label={t("aria_usp_banner_close")} className={css.button} onClick={handleClose}>
          <Icon name="Cross" theme="blue-deep" width={10} height={10} />
        </button>
      </div>
    </div>
  ) : null
}
