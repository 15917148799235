import { useEffect } from "react"

import { useEventsBuffer } from "~/providers/GTMTrackingProvider/hooks/use-events-buffer"
import { useShouldPushEvents } from "~/providers/GTMTrackingProvider/hooks/use-should-push-events"
import { useTracking } from "~/providers/GTMTrackingProvider/hooks/use-tracking"

export function EventsBufferHandler() {
  const [eventsBuffer, setEventsBuffer] = useEventsBuffer()
  const shouldPushEvents = useShouldPushEvents()
  const { pushToDataLayer } = useTracking()

  useEffect(() => {
    if (shouldPushEvents && eventsBuffer.length > 0) {
      eventsBuffer.forEach((event) => {
        pushToDataLayer(event)
      })

      setEventsBuffer([])
    }
  }, [shouldPushEvents])

  return null
}
